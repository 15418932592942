import React, { useEffect, useState } from 'react';
import JwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { managedAccountSwitch } from '../../../api/token/post/managedAccountSwitch';
import { updateUserData } from '../../../utils/chayns-info';
import { getCookie } from '../../../utils/helper';
import { AT_COOKIE_NAME } from '../../../utils/TokenHelper';
import ManagedAccounts from './ManagedAccounts';
import { getUserState } from '../../../redux-modules/user/selector';
import { getEnvState } from '../../../redux-modules/env/selector';
import appCall from '../../../utils/appCall';
import { setUser } from '../../../redux-modules/user/actions';

const ManagedAccountsWrapper = () => {
    const user = useSelector(getUserState);
    const [mainUser, setMainUser] = useState(null);
    const { isMyChaynsApp } = useSelector(getEnvState);

    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (isMyChaynsApp) {
                const { mainUser: appMainUser } = await appCall(285);
                let fixMainUser = appMainUser;
                if (Array.isArray(fixMainUser)) [fixMainUser] = appMainUser;
                if (fixMainUser) {
                    setMainUser({
                        firstname: fixMainUser.firstname || fixMainUser.firstName,
                        lastname: fixMainUser.lastname || fixMainUser.lastName,
                        personId: fixMainUser.personId,
                    });
                } else {
                    const parseToken = JwtDecode(user?.mainToken || user.token);
                    setMainUser({
                        userId: parseToken.TobitUserID,
                        personId: parseToken.PersonID,
                        firstname: parseToken.FirstName,
                        lastname: parseToken.LastName,
                        token: user.mainToken,
                    });
                }
                return;
            }

            if (user?.isGuardedAccountSelected) {
                try {
                    const parseToken = JwtDecode(user.mainToken);
                    setMainUser({
                        userId: parseToken.TobitUserID,
                        personId: parseToken.PersonID,
                        firstname: parseToken.FirstName,
                        lastname: parseToken.LastName,
                        token: user.mainToken,
                    });
                } catch (error) {
                    console.error(error);
                    // ignore
                }
                return;
            }
            setMainUser(user || {});
        })();
    }, [user]);

    if (!mainUser) return null;

    return (
        <ManagedAccounts
            user={user}
            mainUser={mainUser}
            onUserSwitch={async (personId, isMainAccount, incognito) => {
                if (isMyChaynsApp) {
                    appCall(286, {
                        personId,
                        isIncognito: incognito,
                    });
                    dispatch(setUser({
                        ...user,
                        incognito,
                    }));
                    return;
                }

                if (!isMainAccount) {
                    const { status, result } = await managedAccountSwitch(personId, mainUser.token);
                    if (status === 200) {
                        const { token, expires } = result;
                        document.cookie = `chayns_guarded_at=${token}; expires=${new Date(expires).toUTCString()}; path=/`;
                        await updateUserData(true);
                        chayns.getGlobalData();

                        const event = new CustomEvent('login', {
                            detail: {
                                token,
                            },
                        });
                        window.dispatchEvent(event);
                    }
                } else {
                    document.cookie = `chayns_guarded_at=0; expires=${new Date('1970-1-1').toUTCString()}; path=/`;
                    await updateUserData(false);
                    chayns.getGlobalData();

                    const event = new CustomEvent('login', {
                        detail: {
                            token: getCookie(AT_COOKIE_NAME),
                        },
                    });
                    window.dispatchEvent(event);
                }
                window.loggedIn(true, !isMainAccount);
            }}
        />
    );
};

export default ManagedAccountsWrapper;
