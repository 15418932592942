import React from 'react';
import PropTypes from 'prop-types';
import Button from '@chayns-components/Button';
import useGetTextString from '../../../../utils/hooks/useGetTextString';
import getLanguage from '../../../../utils/getLanguage';
import {useSelector} from "react-redux";
import {getAppState} from "../../../../redux-modules/app/selector";

const LoginInfo = ({
    onLoginClick,
}) => {
    const {
        loginOverlay
    } = useSelector(getAppState);
    const { getChaynsDeTextString, getTobitTeamTextString } = useGetTextString();

    return (
        <>
            <div className="login-info">
                <img
                    src="https://tsimg.cloud/77892-10814/0c0ecadc8b290d8f9f2eff1e88255efdb419d72a_w1000.png"
                    alt="Tobit.Team"
                    className="login-info__logo"
                />
                <p
                    className="login-info__text"
                    dangerouslySetInnerHTML={{
                        __html: getTobitTeamTextString('login_info'),
                    }}
                />
                <div className="login-info__login">
                    <Button
                        onClick={onLoginClick}
                    >
                        {getChaynsDeTextString('login')}
                    </Button>
                </div>
                <div className="login-info__links">
                    <a
                        href={`https://play.google.com/store/apps/details?id=com.tobit.android.team&hl=${getLanguage()}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {getTobitTeamTextString('app_link').replace('##PLATFORM##', 'Android')}
                    </a>
                    <span>&nbsp; - &nbsp;</span>
                    <a
                        href="https://apps.apple.com/de/app/tobit-team/id6479241080"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {getTobitTeamTextString('app_link').replace('##PLATFORM##', 'iOS')}
                    </a>
                    <span>&nbsp; - &nbsp;</span>
                    <a
                        href="https://david.tobit.software/tobit.com/team-david"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {getTobitTeamTextString('more_team_david')}
                    </a>
                </div>
            </div>
            <style jsx global>
                {`
                    .login-info {
                        position: fixed;
                        z-index: ${loginOverlay ? 303 : 305};
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                        margin: 0 12px;
                        color: #FFF;

                        &__logo {
                            width: 308px;
                            margin-bottom: 42px;
                        }

                        &__text {
                            font-size: 24px;
                            margin-bottom: 40px;
                    
                            &__registered-trademark {
                                font-size: 14px;
                            }
                        }

                        &__login {
                            margin-bottom: 23px;

                            > button {
                                color: rgb(15, 109, 126) !important;
                                background-color: rgb(243, 243, 243) !important;
                                font-size: 19px;
                            }
                        }

                        &__links {
                            font-size: 16px;
                            
                            > a {
                                border-bottom-color: #FFF;
                            }
                        }
                    }
                `}
            </style>
        </>
    );
};

LoginInfo.propTypes = {
    onLoginClick: PropTypes.func.isRequired,
};

export default LoginInfo;
