import React, { useEffect, useCallback, useState, memo } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';

import { EVENT, INTERCOM, MAIL, NEWS } from '../../../../../constants/types';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../constants/size';
import resizeInterval from '../../../../../utils/resizeInterval';
import { getAppState } from '../../../../../redux-modules/app/selector';
import SelectedCalendarEvent from "./selected-calendar-event/SelectedCalendarEvent";
import TAPPIDS from "../../../../../constants/tapp-ids";
import { setSelectedItem } from "../../../../../redux-modules/app/actions";
import SelectedMail from "./selected-mail/SelectedMail";
import SelectedIntercom from "./selected-intercom/SelectedIntercom";
import SelectedNews from "./selected-news/SelectedNews";

const OverlayElement = ({ infocenter}) => {
    const app = useSelector(getAppState);
    const {
        colorMode,
        width,
        selectedItem,
        activeTapp
    } = app;

    const [topPosition, setTopPosition] = useState(0);

    const dispatch = useDispatch();

    const handleOverlayPosition = useCallback(() => {
        resizeInterval(5, () => {
            setTopPosition(document.querySelector('.sites_wrapper')?.scrollTop || 0);
        });
    }, []);

    useEffect(() => {
        setTopPosition(document.querySelector('.sites_wrapper')?.scrollTop || 0);
        window.addEventListener('resize', handleOverlayPosition);

        return () => {
            window.removeEventListener('resize', handleOverlayPosition);
        };
    }, [handleOverlayPosition, selectedItem]);

    useEffect(() => {
        if(activeTapp !== TAPPIDS.INFO_CENTER) {
            dispatch(setSelectedItem(null));
        }
    }, [activeTapp])

    if (!selectedItem || (!infocenter && width < MOBILE_VIEW_BREAKPOINT)) {
        return null;
    }

    const backgroundColor = colorMode === 'light' ? 'white' : '#1E1E1E';

    return (
        <>
            <CSSTransition
                classNames="overlay-element"
                key="overlay-element"
                timeout={500}
                in={!!selectedItem}
                appear
            >
                <div
                    className={classNames('overlay-element-wrapper', {
                        scrollbar: selectedItem.type === NEWS,
                        'news-item': selectedItem.type === NEWS,
                    })}
                    style={{
                        backgroundColor,
                        top: topPosition,
                    }}
                >
                    {selectedItem.type === MAIL && (
                        <SelectedMail/>
                    )}

                    {selectedItem.type === INTERCOM && (
                        <SelectedIntercom/>
                    )}

                    {selectedItem.type === NEWS && (
                        <SelectedNews/>
                    )}

                    {selectedItem.type === EVENT && (
                        <SelectedCalendarEvent/>
                    )}
                </div>
            </CSSTransition>
            <style jsx global>
                {`
                    .overlay-element-wrapper {
                        position: absolute;
                        transition: opacity .25s;
                        left: 0;
                        z-index: 99;
                        width: 100%;
                        box-shadow: 1px 2px 4px #0000001a;
                        height: 100%;

                        &-enter, &-appear {
                            opacity: 0 !important;
                        }

                        &-enter-active, &-appear-active {
                            opacity: 1 !important;
                        }

                        &-exit {
                            opacity: 1 !important;
                        }

                        &-exit-active, &-exit-done {
                            opacity: 0 !important;
                        }
                    }
                `}
            </style>
        </>
    );
};

OverlayElement.displayName = 'OverlayElement';

export default memo(OverlayElement);
