import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAppState } from '../../../../redux-modules/app/selector';
import IFrame from '../iframe/IFrame';
import { getTappById } from '../../../../utils/tapp/custom-tapp';
import TAPPIDS from '../../../../constants/tapp-ids';
import { getEnvState } from '../../../../redux-modules/env/selector';

const LoginOverlay = () => {
    const {
        loginOverlay,
    } = useSelector(getAppState);

    const {
        isDesktop,
    } = useSelector(getEnvState);

    const frameInfo = useMemo(() => {
        if (!loginOverlay) {
            return null;
        }
        const tapp = getTappById(TAPPIDS.LOGIN_TAPP_ID);
        let value = tapp?.source?.replace('&noBackground=0', '');
        if (!value) {
            value = 'https://login.chayns.net/staging/v3/index.html?AppVersion=2&SiteID=60021-08989&color=005eb8&qrLogin=1&staticMode=1&colormode=1&showLogin=1&sso_exclude=6';
        }

        if (!value.includes('tokenType=') && typeof loginOverlay.tokenType === 'number') {
            value += `&tokenType=${loginOverlay.tokenType}`;
        }

        if (!value.includes('hideLogo=')) {
            value += '&hideLogo=1';
        }

        if (isDesktop) {
            value += '&desktop=1';
        }

        return {
            id: tapp?.id,
            url: `${value}&teamRuntime=1`,
        };
    }, [isDesktop, loginOverlay]);

    return loginOverlay && (
        <div style={{ position: 'fixed', zIndex: 400, top: 0, left: 0, right: 0, bottom: 0 }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: loginOverlay.tokenType === 'default' ? isDesktop ? -8 : 50 : isDesktop ? -8 : 0,
                }}
            >
                <IFrame
                    tappId={frameInfo.id}
                    isActive
                    tappUrl={frameInfo.url}
                    style={{
                        width: '100vw',
                        minHeight: '100vh',
                    }}
                />
            </div>
        </div>
    );
};

export default LoginOverlay;
