/* eslint-disable linebreak-style */
import ROUTES from '../constants/routes';
import TAPPIDS from '../constants/tapp-ids';
import { setActiveTapp } from '../redux-modules/app/actions';

const handleSetActiveTapp = (tappId: number, activeTapp: number, dispatch: ({ payload: number }) => void, prevSmartClientUrl: { current: string }, fromPopState: boolean, isInitialRender: boolean): void => {
    if (!tappId) {
        return;
    }

    if (activeTapp === TAPPIDS.SMART_CLIENT) {
        // eslint-disable-next-line no-param-reassign
        prevSmartClientUrl.current = location.href;
    }

    if (!fromPopState) {
        const tappRoutes = Object.keys(ROUTES.tappRoutes);
        tappRoutes.forEach((currentRoute) => {
            if (ROUTES.tappRoutes[currentRoute] === tappId && tappId !== TAPPIDS.SMART_CLIENT && tappId !== TAPPIDS.CALENDAR) {
                if (isInitialRender) {
                    history.replaceState({ activeTapp: tappId }, document.title, `/${currentRoute}`);
                } else {
                    history.pushState({ activeTapp: tappId }, document.title, `/${currentRoute}`);
                }
            }
        });

        const isStandardTapp = [TAPPIDS.SMART_CLIENT, TAPPIDS.INTERCOM, TAPPIDS.MONEY, TAPPIDS.WALLET, TAPPIDS.CHAYNS_ID, TAPPIDS.SPACE, TAPPIDS.INFO_CENTER, TAPPIDS.LANDING_PAGE, TAPPIDS.CALENDAR].includes(tappId);
        if (!isStandardTapp && typeof window.chaynsInfo !== 'undefined') {
            const tapp = chaynsInfo.Tapps.find((t) => t.id === tappId);
            if (tapp) {
                if (isInitialRender) {
                    history.replaceState({ activeTapp: tappId }, document.title, `/${tapp.customUrl || ''}`);
                } else {
                    history.pushState({ activeTapp: tappId }, document.title, `/${tapp.customUrl || ''}`);
                }
            }
        }
    }

    if (tappId === TAPPIDS.SMART_CLIENT && prevSmartClientUrl.current) {
        history.replaceState(null, '', prevSmartClientUrl.current);
    }

    if (typeof window.chaynsInfo !== 'undefined' && !window.chaynsInfo?.User?.TobitAccessToken && tappId !== TAPPIDS.LANDING_PAGE) {
        return;
    }

    if (tappId >= -2) {
        dispatch(setActiveTapp(tappId));
    }

    if (window.chaynsInfo) {
        window.chaynsInfo.currentTapp = tappId;
    }

    if (typeof chayns !== 'undefined') {
        chayns.utils.resetEnvironment();
    }
};

export default handleSetActiveTapp;
