import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import useLoadedTapps from '../../utils/hooks/useLoadedTapps';
import TAPPIDS from '../../constants/tapp-ids';
import CalendarTapp from '../tapp/tapp-content/calendar-tapp/CalendarTapp';
import { getAppState } from '../../redux-modules/app/selector';
import DavidAppTapp from '../tapp/tapp-content/david-app-tapp/DavidAppTapp';
import Infocenter from '../team-app-infocenter/Infocenter';
import VersionHint from '../version-hint/VersionHint';
import ErrorBoundary from '../error-boundary/ErrorBoundary';
import styles from './app-tapp.module.scss';

const AppTapp: React.FC = () => {
    const usedTapps = useLoadedTapps();

    const { activeTapp } = useSelector(getAppState);

    const loadedTapps = useMemo(() => usedTapps.filter((tapp) => tapp.wasLoaded || tapp.id === TAPPIDS.INFO_CENTER).map((tapp) => {
        let component: React.ReactNode;

        switch (tapp.id) {
            case TAPPIDS.CALENDAR:
                component = (
                    <CalendarTapp isActive={activeTapp === TAPPIDS.CALENDAR}/>
                );
                break;
            case TAPPIDS.SMART_CLIENT:
                component = <DavidAppTapp/>;
                break;
            case TAPPIDS.INFO_CENTER:
                component = <Infocenter/>;
                break;
            default:
                component = null;
        }

        return {
            ...tapp,
            component: (<div key={tapp.id} className={clsx(styles.appTappContent, tapp.id === activeTapp && styles.appTappContentActive)}>{component}</div>),
        };
    }), [activeTapp, usedTapps]);

    return (
        <>
            {loadedTapps.map((tapp) => tapp.component)}
            <ErrorBoundary
                section="versionHint"
            >
                <VersionHint/>
            </ErrorBoundary>
        </>
    );
};

export default AppTapp;
