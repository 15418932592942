import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import UserInfo from './user-info/UserInfo';
import TappItem from './tapp-item/TappItem';
import { Tapp } from '../../../types/tapp';
import TAPPIDS from "../../../constants/tapp-ids";

type MenuTypes = {
    show: boolean,
    tapps: Array<Tapp>,
    handleLoadTapp: (tappId: number) => void,
    isDarkMode: boolean,
    isSmartClientActive: boolean,
    isIOS: boolean
};

const Menu: React.FC<MenuTypes> = ({
    show,
    tapps,
    handleLoadTapp,
    isDarkMode,
    isSmartClientActive,
    isIOS,
}) => (
    <>
        <CSSTransition
            in={show}
            timeout={300}
            mountOnEnter
            unmountOnExit
            classNames="translate"
        >
            <div
                className={classNames('menu-wrapper', {
                    is_smart_client_active: isSmartClientActive,
                })}
            >
                <div
                    className={classNames('menu', { dark_mode: isDarkMode })}
                >
                    <UserInfo
                        isDarkMode={isDarkMode}
                    />
                    {
                        tapps.map((t) => (
                            <TappItem
                                key={t.tappId}
                                name={t.id === TAPPIDS.CHAYNS_SITES ? 'chayns® Sites' : t.name}
                                icon={t.id === TAPPIDS.CHAYNS_SITES ? 'ts-chayns' : t.icon}
                                onClick={() => handleLoadTapp(t.tappId)}
                            />
                        ))
                    }
                </div>
            </div>
        </CSSTransition>
        <style jsx>
            {`
                .menu-wrapper {
                    z-index: 300;
                    overflow: ${isIOS ? 'auto' : 'hidden'};
                    bottom: 70px;
                    width: 412px;
                    margin: auto;
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    pointer-events: none;
                  
                    
                    &.is_smart_client_active {
                        bottom: 90px;
                    }
                    
                    &.translate-enter .menu {
                      transform: translateY(305px);
                    }
                    &.translate-enter-active .menu {
                      transform: translateY(0px);
                    }
                    &.translate-enter-done .menu {
                      transform: translateY(0px);
                    }
                }
                .menu {
                    overflow: hidden;
                    font-weight: 400;
                    position: absolute;
                    background-color: white;
                    left: 0;
                    right: 0;
                    transition: transform 0.3s ease-in;
                    bottom: 0;
                    pointer-events: all;
                    transform: translateY(305px);

                    &.dark_mode {
                        background: #1E1E1E;
                        color: white;
                    }
                }
            `}
        </style>
    </>
);

Menu.propTypes = {
    show: PropTypes.bool.isRequired,
    handleLoadTapp: PropTypes.func.isRequired,
    isDarkMode: PropTypes.bool.isRequired,
    isSmartClientActive: PropTypes.bool,
};

Menu.defaultProps = {
    isSmartClientActive: false,
};

Menu.displayName = 'Menu';

export default Menu;
