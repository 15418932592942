/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import TAPPIDS from '../../../constants/tapp-ids';
import InnerTappContent from './inner-tapp-content/InnerTappContent';
import { getAppState } from '../../../redux-modules/app/selector';
import { getEnvState } from '../../../redux-modules/env/selector';
import { getUserState } from '../../../redux-modules/user/selector';
import { setIsTappScrolled } from '../../../redux-modules/app/actions';
import getBackgroundColor from '../../../utils/getBackgroundColor';
import clsx from "clsx";

type TappContentTypes = {
  isActive: boolean,
  tappId: number,
  overlayActive: boolean,
  isTappAlreadyLoaded?: boolean,
  bundleUrl?: string,
  postTobitAccessToken?: boolean,
  url?: string,
  params?: string | Array<string>,
    apiVersion: number;
};

const TappContent: React.FC<TappContentTypes> = ({
    isActive,
    tappId,
    overlayActive,
    isTappAlreadyLoaded,
    bundleUrl,
    postTobitAccessToken,
    url,
    params,
    apiVersion,
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        colorMode,
        activeTapp,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMobileBrowser,
        isMyChaynsApp,
    } = env;

    const user = useSelector(getUserState);

    const tappIframeWrapperRef = useRef(null);

    const backgroundColor = useMemo(() => {
        if (isMobileBrowser || isMyChaynsApp) {
            return 'transparent';
        }
        return getBackgroundColor(colorMode);
    }, [colorMode, isMobileBrowser, isMyChaynsApp]);

    useEffect(() => {
        if (tappId !== TAPPIDS.INFO_CENTER && isActive && tappIframeWrapperRef.current) {
            const { scrollTop } = tappIframeWrapperRef.current;
            dispatch(setIsTappScrolled(scrollTop > 0));
        }
    }, [dispatch, isActive, tappId]);

    const bodyContentClassNames = classNames('body-content', {
        mobile_browser: isMobileBrowser,
        'login-tapp': tappId === TAPPIDS.LANDING_PAGE,
        'main-page': tappId === TAPPIDS.INFO_CENTER,
    });

    return (
        <>
            <CSSTransition
                classNames="iframe-animation"
                key="tapp-iframe"
                timeout={500}
                in={isActive}
                appear
            >
                <div
                    className={bodyContentClassNames}
                    style={{
                        opacity: isActive ? 1 : 0,
                        zIndex: isActive ? (activeTapp === TAPPIDS.LANDING_PAGE ? 400 : 100) : 0,
                        display: isActive ? 'block' : 'none',
                    }}
                >
                    <InnerTappContent
                        backgroundColor={backgroundColor}
                        bundleUrl={bundleUrl}
                        isActive={isActive}
                        tappIframeWrapperRef={tappIframeWrapperRef}
                        isMobileBrowser={isMobileBrowser}
                        isMyChaynsApp={isMyChaynsApp}
                        isTappAlreadyLoaded={isTappAlreadyLoaded}
                        overlayActive={overlayActive}
                        postTobitAccessToken={postTobitAccessToken}
                        tappId={tappId}
                        user={user}
                        url={url}
                        params={params}
                        apiVersion={apiVersion}
                    />
                </div>
            </CSSTransition>
            <style jsx global>
                {`
                    .body-content {
                        transition: opacity .25s;
                        width: 100%;
                        margin: auto;
                        left: 0;
                        right: 0;
                        position: relative;
                        height: 100%;

                        .main-page-wrapper {
                            height: 100%;
                            display: flex;
                            justify-content: space-between;
                        }

                        .tapp-iframe-wrapper {
                            overflow: hidden auto !important;

                            display: flex;
                            width: 100%;
                            height: 100%;

                            &::-webkit-scrollbar {
                                display: none;
                            }


                            &.scrollable {
                                -webkit-overflow-scrolling: touch;
                            }

                            .tapp-iframe {
                                transition: opacity .25s;
                                width: 100% !important;
                                left: 0;
                                pointer-events: all;
                                min-height: 100%;

                                &:not(.login-tapp) {
                                    background-color: ${colorMode === 'dark' ? '#121212' : 'white'};
                                }

                                // max-height: 400px;


                                &.desktop {
                                    &.login-tapp {
                                        height: 100% !important;
                                    }
                                }
                            }
                        }
                    }

                    .iframe-animation {
                        &-enter {
                            opacity: 0 !important;
                        }

                        &-enter-active {
                            opacity: 1 !important;
                        }

                        &-exit {
                            opacity: 1 !important;
                        }

                        &-exit-active, &-exit-done {
                            opacity: 0 !important;
                            display: none;
                        }
                    }
                `}
            </style>
        </>
    );
};

TappContent.displayName = 'TappContent';

TappContent.propTypes = {
    isActive: PropTypes.bool,
    tappId: PropTypes.number.isRequired,
    overlayActive: PropTypes.bool,
    isTappAlreadyLoaded: PropTypes.bool,
    bundleUrl: PropTypes.string,
    postTobitAccessToken: PropTypes.bool,
    url: PropTypes.string,
    params: PropTypes.arrayOf(PropTypes.string),
};

TappContent.defaultProps = {
    isActive: false,
    overlayActive: false,
    isTappAlreadyLoaded: false,
    bundleUrl: '',
    postTobitAccessToken: false,
    url: undefined,
    params: null,
};

export default TappContent;
