import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import dynamic from 'next/dynamic';
import classNames from 'classnames';

import SitesTapp from '../sites-tapp/SitesTapp';
import ErrorBoundary from '../../../error-boundary/ErrorBoundary';
import Messages from '../sites-tapp/messages/Messages';
import News from '../sites-tapp/news/News';
import IFrame from '../iframe/IFrame';
import TAPPIDS from '../../../../constants/tapp-ids';
import { HEADER_HEIGHT, MOBILE_VIEW_BREAKPOINT, BAR_HEIGHT } from '../../../../constants/size';
import { getJsonCallListener } from '../../../../calls/json-chayns-call/calls/utils/jsonCallListeners';
import { setUrlParams } from '../../../../utils/tapp/custom-tapp';
import { getAppState } from '../../../../redux-modules/app/selector';
import { getIsCalendarShown } from '../../../../redux-modules/smart-client/selector';
import usePrevious from '../../../../utils/hooks/usePrevious';
import { User } from '../../../../types/user';
import getLanguage from '../../../../utils/getLanguage';
import ApiV5Tapp from '../ApiV5Tapp/ApiV5Tapp';
import LoginCompleted from '../login-completed/LoginCompleted';
import CalendarInfoCenter from '../sites-tapp/CalendarInfoCenter';
import SmartClientTapp from '../smartclient-tapp/SmartClientTapp';
import TobitTeamInfoCenter from '../../../tobit-team-info-center/TobitTeamInfoCenter';
import Infocenter from "../../../team-app-infocenter/Infocenter";
import LoginInfo from "../login/LoginInfo";
import {CSSTransition} from "react-transition-group";
import {setLoginOverlay} from "../../../../redux-modules/app/actions";

const CalendarTapp = dynamic(() => import('../calendar-tapp/CalendarTapp'), {
    ssr: false,
});

type InnerTappContentTypes = {
    backgroundColor: string,
    bundleUrl?: string,
    isActive: boolean,
    isMobileBrowser: boolean,
    isMyChaynsApp: boolean,
    isTappAlreadyLoaded: boolean,
    url: string,
    overlayActive: boolean,
    postTobitAccessToken: boolean,
    tappId: number,
    tappIframeWrapperRef: React.LegacyRef<HTMLDivElement>,
    user: User,
    params,
    apiVersion?: number;
};

const InnerTappContent: React.FC<InnerTappContentTypes> = ({
    backgroundColor,
    bundleUrl,
    isActive,
    isMobileBrowser,
    isMyChaynsApp,
    isTappAlreadyLoaded,
    url,
    overlayActive,
    postTobitAccessToken,
    tappId,
    tappIframeWrapperRef,
    user,
    params,
    apiVersion,
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        height,
        isSmartClientAvailable,
        width,
        colorMode,
        isUserAuthenticated,
        onlySpringboard,
    } = app;

    const isCalendarShown = useSelector(getIsCalendarShown);
    const prevParams = usePrevious(params);

    const [reloadIFrame, setReloadIFrame] = useState(0);
    const tappUrl = useRef((() => {
        let temp = url && setUrlParams(url, tappId);
        temp += `&contentWidth=${width}&screenHeight=${height - HEADER_HEIGHT}&hideHeader=true&lang=${getLanguage()}`;
        return temp;
    })());

    useEffect(() => {
        if (tappId !== TAPPIDS.LANDING_PAGE && tappId !== TAPPIDS.INFO_CENTER) {
            const srcFrame = document.getElementById(`TappIframe_${tappId > 0 ? tappId : tappId * -1}`);
            if (srcFrame) {
                const isAccessTokenChangeListenerAdded = getJsonCallListener(66, srcFrame);
                if (!isAccessTokenChangeListenerAdded) {
                    setReloadIFrame(reloadIFrame + 1);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.token]);

    useEffect(() => {
        let isEqual = false;
        if (Array.isArray(params) && Array.isArray(prevParams)) {
            isEqual = params.length === prevParams.length && params.every((val, i) => val === prevParams[i]);
        }

        if (params && !isEqual) {
            const paramArr = Array.isArray(params) ? params : [params];
            const paramJoined = paramArr.join('&');
            if (!tappUrl.current.includes(`&${paramJoined}`)) {
                tappUrl.current += `&${paramJoined}`;
            }
            setReloadIFrame((state) => state + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const iFrameStyles = useMemo(() => {
        if (!isActive) {
            return {
                zIndex: '0',
                pointerEvents: 'none',
            } as React.CSSProperties;
        }
        const tempStyles = {
            zIndex: '100',
            padding: '',
            pointerEvents: 'auto',
        } as React.CSSProperties;
        if (overlayActive) {
            tempStyles.pointerEvents = 'none';
        }
        return tempStyles;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, overlayActive, height, tappId, isTappAlreadyLoaded]);

    if (tappId === TAPPIDS.INFO_CENTER) {
        if (!isUserAuthenticated) {
            return null;
        }
        if (width < MOBILE_VIEW_BREAKPOINT) {
            return (
                <ErrorBoundary
                    section="infoCenter"
                >
                    <Infocenter/>
                </ErrorBoundary>
            );
        }

        const elemBackgroundColor = (() => {
            if (!isMyChaynsApp) {
                if (width >= MOBILE_VIEW_BREAKPOINT) {
                    return colorMode === 'light' ? 'white' : '#1E1E1E';
                }
                return colorMode === 'light' ? '#f7f7f7' : '#121212';
            }
            return 'unset';
        })();

        return (
            <div
                className={classNames('main-page-wrapper')}
                style={{
                    backgroundColor: width < MOBILE_VIEW_BREAKPOINT ? backgroundColor : null,
                }}
            >
                {onlySpringboard ? <SitesTapp/> : (
                    <>
                        <ErrorBoundary
                            section="messages"
                        >
                            {!isSmartClientAvailable && <div/>}
                            {isSmartClientAvailable && <Messages/>}
                        </ErrorBoundary>
                        <ErrorBoundary
                            section="tobitTeamInfoCenter"
                        >
                            <TobitTeamInfoCenter/>
                        </ErrorBoundary>
                        <ErrorBoundary
                            section="rightContentWrapper"
                        >
                            {!isSmartClientAvailable && <div/>}
                            {isSmartClientAvailable && (
                                <>
                                    <div className="main-page-right">
                                        {isCalendarShown && (
                                            <ErrorBoundary
                                                section="calendarInfoCenter"
                                            >
                                                <CalendarInfoCenter backgroundColor={elemBackgroundColor}/>
                                            </ErrorBoundary>
                                        )}

                                        <ErrorBoundary
                                            section="News"
                                        >
                                            <News
                                                backgroundColor={elemBackgroundColor}
                                            />
                                        </ErrorBoundary>
                                    </div>
                                    <style jsx>
                                        {`
                                            .main-page-right {
                                                display: flex;
                                                flex-direction: column;
                                                width: 20%;
                                                min-width: 250px;
                                                max-width: 420px;
                                                z-index: 1;
                                                border-left: 1px solid ${colorMode === 'dark' ? 'rgba(255, 255, 255, .15)' : 'rgba(0, 0, 0, 0.15)'};
                                            }
                                        `}
                                    </style>
                                </>
                            )}
                        </ErrorBoundary>
                    </>
                )}
            </div>
        );
    }

    if (bundleUrl && tappId === TAPPIDS.SMART_CLIENT) {
        return (
            <ErrorBoundary
                section="smartclient"
            >
                <SmartClientTapp/>
            </ErrorBoundary>
        );
    }

    if (tappId === TAPPIDS.CALENDAR || (tappId === TAPPIDS.SMART_CLIENT)) {
        return (
            <ErrorBoundary
                section="calendarTapp"
            >
                <CalendarTapp
                    isActive={isActive}
                />
            </ErrorBoundary>
        );
    }

    if (tappId === TAPPIDS.CHAYNS_SITES) {
        return <SitesTapp/>;
    }

    if (!tappUrl.current) {
        return null;
    }

    if (tappId === TAPPIDS.LOGIN_COMPLETED) {
        return <LoginCompleted/>;
    }

    if (apiVersion === 5) {
        return (
            <ErrorBoundary
                section="iFrame"
            >
                <ApiV5Tapp
                    key={`${tappId}_${reloadIFrame}`}
                    tappId={tappId}
                    tappIframeWrapperRef={tappIframeWrapperRef}
                    postTobitAccessToken={postTobitAccessToken}
                    tappUrl={url}
                    isActive={isActive}
                    overlayActive={overlayActive}
                    isTappAlreadyLoaded={isTappAlreadyLoaded}
                    isMobileBrowser={isMobileBrowser}
                    isMyChaynsApp={isMyChaynsApp}
                    iFrameStyles={iFrameStyles}
                    wrapperClassNames={classNames('tapp-iframe-wrapper', 'scrollbar', {
                        scrollable: isMobileBrowser && tappId !== TAPPIDS.LANDING_PAGE,
                        'not-active': !isActive,
                    })}
                />
            </ErrorBoundary>
        );
    }
    return (
        <ErrorBoundary
            section="iFrame"
        >
            <IFrame
                key={`${tappId}_${reloadIFrame}`}
                tappId={tappId}
                height={height - HEADER_HEIGHT - BAR_HEIGHT}
                tappIframeWrapperRef={tappIframeWrapperRef}
                postTobitAccessToken={postTobitAccessToken}
                tappUrl={tappUrl.current}
                isActive={isActive}
                overlayActive={overlayActive}
                isTappAlreadyLoaded={isTappAlreadyLoaded}
                isMobileBrowser={isMobileBrowser}
                isMyChaynsApp={isMyChaynsApp}
            />
        </ErrorBoundary>
    );
};

InnerTappContent.displayName = 'InnerTappContent';

InnerTappContent.defaultProps = {
    bundleUrl: null,
};

export default memo(InnerTappContent);
